<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card no-body header-tag="header" class="card-w-shadow">
          <template #header>
            <small>{{ leftYear }}</small>
            <h2>{{ leftDate }}</h2>
            <hr class="mb-0" />
          </template>

          <b-card-body class="p-2">
            <b-card-text>
              <b-img fluid :src="leftImage.url"></b-img>

              <b-row>
                <b-col class="mt-4 text-center">
                  <div class="d-flex justify-content-center">
                    <DownloadButton @click="handleDownloadPhotos(leftImage)" />
                    <SendButton class="ml-3" @click="openSendPhotoModalLeft = true" />
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col>
        <b-card no-body header-tag="header" class="card-w-shadow">
          <template #header>
            <small>{{ rightYear }}</small>
            <h2>{{ rightDate }}</h2>
            <hr class="mb-0" />
          </template>

          <b-card-body class="p-2">
            <b-card-text>
              <b-img fluid :src="rigthImage.url"></b-img>

              <b-row>
                <b-col class="mt-4">
                  <div class="d-flex justify-content-center">
                    <DownloadButton @click="handleDownloadPhotos(rigthImage)" />
                    <SendButton class="ml-3" @click="openSendPhotoModalRight = true" />
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <SendPhotoModal
      :photo="leftImage"
      :open="openSendPhotoModalLeft"
      @close="openSendPhotoModalLeft = false"
    />

    <SendPhotoModal
      :photo="rigthImage"
      :open="openSendPhotoModalRight"
      @close="openSendPhotoModalRight = false"
    />
  </b-container>
</template>

<script>
import { mapState } from 'vuex';
import dateUtil from '@/util/date';
import SendButton from '@/components/SendButton';
import DownloadButton from '@/components/DownloadButton';
import SendPhotoModal from '@/components/SendPhotoModal';

export default {
  name: 'PhotoCardParallel',
  props: {
    leftImage: { type: Object, required: true },
    rigthImage: { type: Object, required: true },
  },
  components: {
    SendButton,
    DownloadButton,
    SendPhotoModal,
  },
  data() {
    return {
      openSendPhotoModalLeft: false,
      openSendPhotoModalRight: false,
      loading: false,
      error: '',
    };
  },
  computed: {
    ...mapState(['camera']),
    leftDate() {
      return dateUtil.toHumanString(this.leftImage.captured_at, this.$i18n.locale);
    },
    leftYear() {
      return dateUtil.getYear(this.leftImage.captured_at);
    },
    rightDate() {
      return dateUtil.toHumanString(this.rigthImage.captured_at, this.$i18n.locale);
    },
    rightYear() {
      return dateUtil.getYear(this.rigthImage.captured_at);
    },
  },
  methods: {
    async handleDownloadPhotos(photo) {
      this.loading = true;
      this.error = '';

      /* let params = {
        startDate:photo.captured_at,
        endDate:photo.created_at,
        camera: this.camera.camera,
        photo,
      };
      this.downloadPhotos(params); */

      const blob = await fetch(photo.url, { cache: 'no-cache' }).then((response) =>
        response.blob(),
      );
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = photo.filename;
      link.click();
    },

    async downloadPhotos(params) {
      try {
        const response = await this.$store.dispatch('photo/download', params);
        openDownloadDialog(response);
      } catch (error) {
        console.log(error);

        if (error.response && error.response.data) {
          if (error.response.status === 500) {
            return (this.error = 'Ocurrió un error al descargar las fotografias.');
          }

          const jsonError = JSON.parse(await error.response.data.text());

          if (jsonError.error) {
            this.error = jsonError.error.message;
          }

          if (jsonError.errors) {
            const firstError = Object.keys(jsonError.errors)[0];
            this.error = jsonError.errors[firstError][0];
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
};

function openDownloadDialog(response) {
  const type = response.headers['content-type'];
  const filename = response.headers['content-disposition'].match(/filename=(.+)$/)[1];
  const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.card {
  &-w {
    &-shadow {
      -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
      -ms-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
      -o-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);

      .card {
        &-header {
          background-color: transparent;
          border-bottom: 0;
          padding: 0.5rem;

          h2 {
            font-family: 'Lato', sans-serif;
            font-size: 1rem;
            font-weight: 900;
            line-height: 34px;
            text-transform: capitalize;

            @include media-breakpoint-up(lg) {
              font-size: 28px;
            }
          }

          hr {
            border-top: 1px solid #c1c1c1;
          }

          small {
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }
}
</style>
