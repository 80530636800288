<template>
  <AppLayout>
    <b-overlay :show="show" rounded="sm" v-if="show">
      <br><br><br><br><br><br><br><br><br><br><br>
    </b-overlay>
    <div
      v-if="!show"
      class="viewport"
      style="height: 100%; display: flex; justify-content: center; align-items: center; background: #242424;"
    >
      
      <VueCompareImage
        v-if="!toggleParallel"
        :leftImage="leftImage ? leftImage.url : null"
        :rightImage="rightImage ? rightImage.url : null"
      />
      <ProjectSelectorButton v-if="!toggleParallel" :esPublico="isPublic"/>
     
      
      <PhotoCardParallel v-if="toggleParallel" :leftImage="leftImage" :rigthImage="rightImage" />
      
      <CameraSelectorDrawer
        :project="project.project"
        :current-camera="camera.camera"
        :show="activeDrawer === 'camera-drawer'"
        :map="false"
        @select="selectCamera"
        @close="activeDrawer = ''"
        v-if="!isPublic"
      />

      <PhotoSelectorDrawer
        :camera="camera.camera"
        :compare="true"
        :date="leftImageDate"
        :show="activeDrawer === 'photo-drawer-left'"
        v-model="leftImage"
        @close="activeDrawer = ''"
        close-on-select-photo
        :esPublico="isPublic"
      >
        <template v-slot:titledrawer>
          {{$t('lang.imagen_izquierda.msg')}}
          <svg width="40px" height="30px" viewBox="0 0 601 501">
            <g id="exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                id="icn-left"
                transform="translate(300.000000, 250.000000) rotate(-180.000000) translate(-300.000000, -250.000000) translate(0.000000, 0.000000)"
              >
                <path
                  d="M0.0001,29.9998 L0.0001,469.9998 C0.0001,486.5688 13.4321,499.9998 30.0001,499.9998 L285.4761,499.9998 L285.4761,-0.0002 L30.0001,-0.0002 C13.4321,-0.0002 0.0001,13.4318 0.0001,29.9998"
                  id="Fill-1"
                  fill="#C1C1C1"
                ></path>
                <path
                  d="M570.0001,-0.0002 L314.5241,-0.0002 L314.5241,499.9998 L570.0001,499.9998 C586.5691,499.9998 600.0001,486.5688 600.0001,469.9998 L600.0001,29.9998 C600.0001,13.4318 586.5691,-0.0002 570.0001,-0.0002"
                  id="Fill-4"
                  fill="#212A52"
                ></path>
              </g>
            </g>
          </svg>
        </template>
      </PhotoSelectorDrawer>

      <PhotoSelectorDrawer
        :camera="camera.camera"
        :compare="true"
        :date="rightImageDate"
        :show="activeDrawer === 'photo-drawer-right'"
        v-model="rightImage"
        @close="activeDrawer = ''"
        close-on-select-photo
        :esPublico="isPublic"
      >
        <template v-slot:titledrawer>
          {{$t('lang.imagen_derecha.msg')}}
          <svg width="40px" height="30px" viewBox="0 0 600 500">
            <g id="exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="icn-right">
                <path
                  d="M0.0001,29.9998 L0.0001,469.9998 C0.0001,486.5688 13.4321,499.9998 30.0001,499.9998 L285.4761,499.9998 L285.4761,-0.0002 L30.0001,-0.0002 C13.4321,-0.0002 0.0001,13.4318 0.0001,29.9998"
                  id="Fill-1"
                  fill="#C1C1C1"
                ></path>
                <path
                  d="M570.0001,-0.0002 L314.5241,-0.0002 L314.5241,499.9998 L570.0001,499.9998 C586.5691,499.9998 600.0001,486.5688 600.0001,469.9998 L600.0001,29.9998 C600.0001,13.4318 586.5691,-0.0002 570.0001,-0.0002"
                  id="Fill-4"
                  fill="#212A52"
                ></path>
              </g>
            </g>
          </svg>
        </template>
      </PhotoSelectorDrawer>
    </div>

    <template v-slot:actionbar>
      <div class="d-flex">
        <div class="d-none d-lg-inline-flex flex-column align-items-center align-self-end mr-3">
          <b-button
            class="btn-circle mb-1 px-0"
            variant="primary"
            @click="$router.push({ name: 'camera-home', params: { esPublico: isPublic ? 'public' : undefined  } })"
          >
            <i class="tmlps-icn-back h3 m-0"></i>
          </b-button>
          <div class="small text-center">{{$t('lang.return.msg')}}</div>
        </div>
        <div class="d-flex flex-grow-1 justify-content-center">
          <PhotoDateSelectorButton
            class="flex-grow-1 flex-lg-grow-0"
            :active="activeDrawer === 'photo-drawer-left'"
            :photo="leftImage ? leftImage : {}"
            @click="toggleDrawer('photo-drawer-left')"
          />

          <CameraSelectorButton
            class="d-none d-lg-inline ml-3 "
            :active="activeDrawer === 'camera-drawer'"
            :disabled="isPublic"
            :camera="camera.camera"
            @click="toggleDrawer('camera-drawer')"
          />

          <PhotoDateSelectorButton
            class="flex-grow-1 ml-3 flex-lg-grow-0"
            :active="activeDrawer === 'photo-drawer-right'"
            :photo="rightImage ? rightImage : {}"
            @click="toggleDrawer('photo-drawer-right')"
          />
        </div>

        <!-- AGREGANDO LOS BOTONES -->
        <br>
        <div class="d-inline-flex flex-column align-items-center ml-3">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(1)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.1_dia.msg')}}</div>
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(2)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.1_semana.msg')}}</div>
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(3)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.1_mes.msg')}}</div>
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3" v-if="isPublic">
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3" v-if="!isPublic">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(4)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.3_meses.msg')}}</div>
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3" v-if="!isPublic">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(5)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.6_meses.msg')}}</div>
        </div>
        <div class="d-inline-flex flex-column align-items-center ml-3" v-if="!isPublic">
          <button
            class="btn-transparent btn-circle btn-line mb-1"
            @click="compararTiempo(6)"
          >
            <i>
              <img src="@/assets/btn-comparar.png" alt="imagen de comparar" style="width:100%;">
            </i>
          </button>
          <div class="small text-center">{{$t('lang.1_anio.msg')}}</div>
        </div>
          <!-- FIN DE AGREGAR LOS BOTONES -->

        <div class="d-none d-lg-inline-flex flex-column align-items-center align-self-end " v-if="!isPublic">
          <button
            class="btn-circle btn-line mb-1"
            :class="toggleParallel ? 'btn-active-parallel' : 'btn-transparent'"
            @click="toggleParallel = !toggleParallel"
          >
            <i
              class="tmlps-icn-parallel m-0"
              :class="toggleParallel ? 'color-white' : 'text-muted'"
            ></i>
          </button>
          <div class="small text-center">{{$t('lang.compare.msg')}}</div>
        </div>
      </div>

      <div class="divider mt-2 d-lg-none"></div>

      <div class="d-flex mt-2 d-lg-none">
        <div class="d-inline-flex flex-column align-items-center align-self-end mr-3">
          <b-button
            class="btn-circle mb-1 px-0"
            variant="primary"
            @click="$router.push({ name: 'camera-home', params: { esPublico: isPublic ? 'public' : undefined  }})"
          >
            <i class="tmlps-icn-back h3 m-0"></i>
          </b-button>
          <div class="small text-center">{{$t('lang.return.msg')}}</div>
        </div>

        <CameraSelectorButton
          class="mx-auto flex-grow-1"
          :active="activeDrawer === 'camera-drawer'"
          :camera="camera.camera"
          @click="toggleDrawer('camera-drawer')"
        />
          
        <div class="d-inline-flex flex-column align-items-center align-self-end ml-3">
          <button
            class="btn-circle btn-line mb-1"
            :class="toggleParallel ? 'btn-active-parallel' : 'btn-transparent'"
            @click="toggleParallel = !toggleParallel"
          >
            <i
              class="tmlps-icn-parallel m-0"
              :class="toggleParallel ? 'color-white' : 'text-muted'"
            ></i>
          </button>
          <div class="small text-center">{{$t('lang.compare.msg')}}</div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import VueCompareImage from 'vue-compare-image';
import CameraSelectorButton from '@/components/CameraSelectorButton';
import ProjectSelectorButton from '@/components/ProjectSelectorButton';
import PhotoCardParallel from '@/components/PhotoCardParallel';
import PhotoDateSelectorButton from '@/components/PhotoDateSelectorButton';
import PhotoSelectorDrawer from '@/components/PhotoSelectorDrawer.vue';
import CameraSelectorDrawer from '@/components/CameraSelectorDrawer';
export default {
  name: 'CameraCompare',
  components: {
    CameraSelectorButton,
    CameraSelectorDrawer,
    PhotoCardParallel,
    PhotoDateSelectorButton,
    PhotoSelectorDrawer,
    ProjectSelectorButton,
    VueCompareImage,
  },
  data() {
    return {
      isPublic: false,
      activeDrawer: '',
      images:[],
      leftImage: null,
      leftImageDate: new Date(),
      rightImage: null,
      rightImageDate: new Date(),
      toggleParallel: false,
      show:true,
    };
  },
  computed: {
    ...mapState(['project', 'camera', 'photo']),
    
  },
  methods: {
    selectCamera(camera) {
      if (this.camera.camera.id !== camera.id) {
        this.$router.push({ name: 'camera-compare', params: { id: camera.id } });
      }
    },

    toggleDrawer(drawer) {
      if (this.activeDrawer === drawer) {
        this.activeDrawer = '';
      } else {
        this.activeDrawer = drawer;
      }
    },
addDaysToDate(date, days){
    var res = new Date(date);
    res.setDate(res.getDate() - days);
    return res;
    },
    async init() {

      

      const lastPhoto = this.camera.camera.last_photo;
      let fechaInicial = new Date(lastPhoto.captured_at);
      this.show = true;
      let fechaFinal = this.addDaysToDate(fechaInicial, 31);
      this.images = await getPhotos(
      this.camera.camera,
      fechaFinal,
      fechaInicial,
      );
      const tam = this.images.length;
      this.leftImage = this.images[tam-1];
      this.rightImage = lastPhoto;


      console.log(new Date(this.rightImage.captured_at),"imagen derecha date");
      console.log(new Date(this.leftImage.captured_at),"imagen izquierdad date");
      //comentado por marco por que traia imagenes de otras horas
      this.leftImageDate = new Date(this.leftImage.captured_at);
      this.rightImageDate = new Date(this.rightImage.captured_at);


      if(this.isPublic){ //si es publico poner por defecto 1 mes
        await this.compararTiempo(3)
      }
      
      this.show = false;
    },
    async compararTiempo(tiempo)
    {
      //Tiempo 1.- 1dia, 2.- 1semana, 3.- 1mes, 4.- 3meses, 5.- 6meses. 6.- 1anio.
      //const fechaPhoto = this.photo.photos[0].captured_at;

      const fechaPhoto = this.rightImage.captured_at;
      console.log("🚀 ~ line 339 ~ fechaPhoto", fechaPhoto)
      this.show = true;
      let fechaInicial = new Date(fechaPhoto);
      let fechaFinal ='';
      if(tiempo == 1){
        fechaFinal = this.addDaysToDate(fechaInicial, 1);
      }
      if(tiempo == 2){
        fechaFinal = this.addDaysToDate(fechaInicial, 7);
      }
      if(tiempo == 3){
        fechaFinal = this.addDaysToDate(fechaInicial, 30);
      }
      if(tiempo == 4){
        fechaFinal = this.addDaysToDate(fechaInicial, 90);
      }
      if(tiempo == 5){
        fechaFinal = this.addDaysToDate(fechaInicial, 180);
      }
      if(tiempo == 6){
        fechaFinal = this.addDaysToDate(fechaInicial, 365);
      }
      console.log("fechaInicial",fechaInicial);
      console.log("fechaFinal",fechaFinal);

      this.images = await getPhotos(
      this.camera.camera,
      fechaFinal,
      fechaInicial,
    );
    console.log('This.Images: ',this.images);
    const tam = this.images.length;
    const exist = compararFechas(fechaFinal,this.images[tam-1]);
    
    //if(exist == true){
      this.leftImage = this.images[tam-1];
        this.rightImage = this.images[0];
        
        this.leftImageDate = new Date(this.images[tam-1].captured_at);
        this.rightImageDate = new Date(this.images[0].captured_at);
    // }else{
    //   console.error("No se encontraron ficheros")
    //   this.$bvToast.toast(`No se encontraron ficheros`, {
    //       title: 'Lo sentimos',
    //       autoHideDelay: 5000,
    //       appendToast: true
    //     })
    // }

    this.show = false;
    },
    
  },

  beforeRouteUpdate(to, from, next) {
    this.init();
    next();
  },
  created() {
    this.init();

    this.isPublic = this.$route.params.esPublico == 'public';
    console.log("🚀 created ~ this.isPublic:", this.isPublic)
  },
};
async function getPhotos(camera, startDate, endDate, selectionStrategy) {
  try {
    return store.dispatch('photo/fetchPhotos', { camera, startDate, endDate, selectionStrategy, esPublico:true });
  } catch (error) {
    store.dispatch('notification/addError', 'Ocurrió un error al descargar las imágenes.', {
      root: true,
    });
    throw error;
  }
}
function compararFechas(fecha, imagen){
  const dia = fecha.getDay();
  const mes = fecha.getMonth();
  const anio = fecha.getFullYear();
  const esperado = new Date(imagen.captured_at);
  const dia2 = esperado.getDay();
  const mes2 = esperado.getMonth();
  const anio2 = esperado.getFullYear();
  if(dia == dia2 && mes == mes2 && anio == anio2){
    return true;
  }else{
    return false;
  }
}
</script>

<style lang="scss" scoped>
.btn {
  &-active {
    &-parallel {
      background-color: var(--primary);

      &:active,
      &:focus {
        background-color: var(--primary);
        box-shadow: none;
      }
    }
  }

  &-transparent {
    background-color: transparent;

    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }
}
</style>

<style lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.vci-container {
  width: auto !important;

  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  .left-image {
    display: block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
  }

  .right-image {
    display: block;
    max-height: 100%;
    max-width: 100%;
    height: auto !important;
    width: auto !important;
  }
}

.divider {
  height: 2px;
  background: #c1c1c1;
}
</style>
