var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c("div", { staticClass: "d-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-none d-lg-inline-flex flex-column align-items-center align-self-end mr-3"
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-circle mb-1 px-0",
                        attrs: { variant: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "camera-home",
                              params: {
                                esPublico: _vm.isPublic ? "public" : undefined
                              }
                            })
                          }
                        }
                      },
                      [_c("i", { staticClass: "tmlps-icn-back h3 m-0" })]
                    ),
                    _c("div", { staticClass: "small text-center" }, [
                      _vm._v(_vm._s(_vm.$t("lang.return.msg")))
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex flex-grow-1 justify-content-center" },
                  [
                    _c("PhotoDateSelectorButton", {
                      staticClass: "flex-grow-1 flex-lg-grow-0",
                      attrs: {
                        active: _vm.activeDrawer === "photo-drawer-left",
                        photo: _vm.leftImage ? _vm.leftImage : {}
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleDrawer("photo-drawer-left")
                        }
                      }
                    }),
                    _c("CameraSelectorButton", {
                      staticClass: "d-none d-lg-inline ml-3 ",
                      attrs: {
                        active: _vm.activeDrawer === "camera-drawer",
                        disabled: _vm.isPublic,
                        camera: _vm.camera.camera
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleDrawer("camera-drawer")
                        }
                      }
                    }),
                    _c("PhotoDateSelectorButton", {
                      staticClass: "flex-grow-1 ml-3 flex-lg-grow-0",
                      attrs: {
                        active: _vm.activeDrawer === "photo-drawer-right",
                        photo: _vm.rightImage ? _vm.rightImage : {}
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleDrawer("photo-drawer-right")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-inline-flex flex-column align-items-center ml-3"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-transparent btn-circle btn-line mb-1",
                        on: {
                          click: function($event) {
                            return _vm.compararTiempo(1)
                          }
                        }
                      },
                      [
                        _c("i", [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              src: require("@/assets/btn-comparar.png"),
                              alt: "imagen de comparar"
                            }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "small text-center" }, [
                      _vm._v(_vm._s(_vm.$t("lang.1_dia.msg")))
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-inline-flex flex-column align-items-center ml-3"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-transparent btn-circle btn-line mb-1",
                        on: {
                          click: function($event) {
                            return _vm.compararTiempo(2)
                          }
                        }
                      },
                      [
                        _c("i", [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              src: require("@/assets/btn-comparar.png"),
                              alt: "imagen de comparar"
                            }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "small text-center" }, [
                      _vm._v(_vm._s(_vm.$t("lang.1_semana.msg")))
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-inline-flex flex-column align-items-center ml-3"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-transparent btn-circle btn-line mb-1",
                        on: {
                          click: function($event) {
                            return _vm.compararTiempo(3)
                          }
                        }
                      },
                      [
                        _c("i", [
                          _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              src: require("@/assets/btn-comparar.png"),
                              alt: "imagen de comparar"
                            }
                          })
                        ])
                      ]
                    ),
                    _c("div", { staticClass: "small text-center" }, [
                      _vm._v(_vm._s(_vm.$t("lang.1_mes.msg")))
                    ])
                  ]
                ),
                _vm.isPublic
                  ? _c("div", {
                      staticClass:
                        "d-inline-flex flex-column align-items-center ml-3"
                    })
                  : _vm._e(),
                !_vm.isPublic
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-flex flex-column align-items-center ml-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent btn-circle btn-line mb-1",
                            on: {
                              click: function($event) {
                                return _vm.compararTiempo(4)
                              }
                            }
                          },
                          [
                            _c("i", [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  src: require("@/assets/btn-comparar.png"),
                                  alt: "imagen de comparar"
                                }
                              })
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "small text-center" }, [
                          _vm._v(_vm._s(_vm.$t("lang.3_meses.msg")))
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.isPublic
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-flex flex-column align-items-center ml-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent btn-circle btn-line mb-1",
                            on: {
                              click: function($event) {
                                return _vm.compararTiempo(5)
                              }
                            }
                          },
                          [
                            _c("i", [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  src: require("@/assets/btn-comparar.png"),
                                  alt: "imagen de comparar"
                                }
                              })
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "small text-center" }, [
                          _vm._v(_vm._s(_vm.$t("lang.6_meses.msg")))
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.isPublic
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-inline-flex flex-column align-items-center ml-3"
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn-transparent btn-circle btn-line mb-1",
                            on: {
                              click: function($event) {
                                return _vm.compararTiempo(6)
                              }
                            }
                          },
                          [
                            _c("i", [
                              _c("img", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  src: require("@/assets/btn-comparar.png"),
                                  alt: "imagen de comparar"
                                }
                              })
                            ])
                          ]
                        ),
                        _c("div", { staticClass: "small text-center" }, [
                          _vm._v(_vm._s(_vm.$t("lang.1_anio.msg")))
                        ])
                      ]
                    )
                  : _vm._e(),
                !_vm.isPublic
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-none d-lg-inline-flex flex-column align-items-center align-self-end "
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn-circle btn-line mb-1",
                            class: _vm.toggleParallel
                              ? "btn-active-parallel"
                              : "btn-transparent",
                            on: {
                              click: function($event) {
                                _vm.toggleParallel = !_vm.toggleParallel
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "tmlps-icn-parallel m-0",
                              class: _vm.toggleParallel
                                ? "color-white"
                                : "text-muted"
                            })
                          ]
                        ),
                        _c("div", { staticClass: "small text-center" }, [
                          _vm._v(_vm._s(_vm.$t("lang.compare.msg")))
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "divider mt-2 d-lg-none" }),
              _c(
                "div",
                { staticClass: "d-flex mt-2 d-lg-none" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-flex flex-column align-items-center align-self-end mr-3"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-circle mb-1 px-0",
                          attrs: { variant: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$router.push({
                                name: "camera-home",
                                params: {
                                  esPublico: _vm.isPublic ? "public" : undefined
                                }
                              })
                            }
                          }
                        },
                        [_c("i", { staticClass: "tmlps-icn-back h3 m-0" })]
                      ),
                      _c("div", { staticClass: "small text-center" }, [
                        _vm._v(_vm._s(_vm.$t("lang.return.msg")))
                      ])
                    ],
                    1
                  ),
                  _c("CameraSelectorButton", {
                    staticClass: "mx-auto flex-grow-1",
                    attrs: {
                      active: _vm.activeDrawer === "camera-drawer",
                      camera: _vm.camera.camera
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleDrawer("camera-drawer")
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-inline-flex flex-column align-items-center align-self-end ml-3"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-circle btn-line mb-1",
                          class: _vm.toggleParallel
                            ? "btn-active-parallel"
                            : "btn-transparent",
                          on: {
                            click: function($event) {
                              _vm.toggleParallel = !_vm.toggleParallel
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "tmlps-icn-parallel m-0",
                            class: _vm.toggleParallel
                              ? "color-white"
                              : "text-muted"
                          })
                        ]
                      ),
                      _c("div", { staticClass: "small text-center" }, [
                        _vm._v(_vm._s(_vm.$t("lang.compare.msg")))
                      ])
                    ]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.show
        ? _c("b-overlay", { attrs: { show: _vm.show, rounded: "sm" } }, [
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br"),
            _c("br")
          ])
        : _vm._e(),
      !_vm.show
        ? _c(
            "div",
            {
              staticClass: "viewport",
              staticStyle: {
                height: "100%",
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                background: "#242424"
              }
            },
            [
              !_vm.toggleParallel
                ? _c("VueCompareImage", {
                    attrs: {
                      leftImage: _vm.leftImage ? _vm.leftImage.url : null,
                      rightImage: _vm.rightImage ? _vm.rightImage.url : null
                    }
                  })
                : _vm._e(),
              !_vm.toggleParallel
                ? _c("ProjectSelectorButton", {
                    attrs: { esPublico: _vm.isPublic }
                  })
                : _vm._e(),
              _vm.toggleParallel
                ? _c("PhotoCardParallel", {
                    attrs: {
                      leftImage: _vm.leftImage,
                      rigthImage: _vm.rightImage
                    }
                  })
                : _vm._e(),
              !_vm.isPublic
                ? _c("CameraSelectorDrawer", {
                    attrs: {
                      project: _vm.project.project,
                      "current-camera": _vm.camera.camera,
                      show: _vm.activeDrawer === "camera-drawer",
                      map: false
                    },
                    on: {
                      select: _vm.selectCamera,
                      close: function($event) {
                        _vm.activeDrawer = ""
                      }
                    }
                  })
                : _vm._e(),
              _c("PhotoSelectorDrawer", {
                attrs: {
                  camera: _vm.camera.camera,
                  compare: true,
                  date: _vm.leftImageDate,
                  show: _vm.activeDrawer === "photo-drawer-left",
                  "close-on-select-photo": "",
                  esPublico: _vm.isPublic
                },
                on: {
                  close: function($event) {
                    _vm.activeDrawer = ""
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "titledrawer",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lang.imagen_izquierda.msg")) +
                              " "
                          ),
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "40px",
                                height: "30px",
                                viewBox: "0 0 601 501"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "exports",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "icn-left",
                                        transform:
                                          "translate(300.000000, 250.000000) rotate(-180.000000) translate(-300.000000, -250.000000) translate(0.000000, 0.000000)"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M0.0001,29.9998 L0.0001,469.9998 C0.0001,486.5688 13.4321,499.9998 30.0001,499.9998 L285.4761,499.9998 L285.4761,-0.0002 L30.0001,-0.0002 C13.4321,-0.0002 0.0001,13.4318 0.0001,29.9998",
                                          id: "Fill-1",
                                          fill: "#C1C1C1"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M570.0001,-0.0002 L314.5241,-0.0002 L314.5241,499.9998 L570.0001,499.9998 C586.5691,499.9998 600.0001,486.5688 600.0001,469.9998 L600.0001,29.9998 C600.0001,13.4318 586.5691,-0.0002 570.0001,-0.0002",
                                          id: "Fill-4",
                                          fill: "#212A52"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  902424706
                ),
                model: {
                  value: _vm.leftImage,
                  callback: function($$v) {
                    _vm.leftImage = $$v
                  },
                  expression: "leftImage"
                }
              }),
              _c("PhotoSelectorDrawer", {
                attrs: {
                  camera: _vm.camera.camera,
                  compare: true,
                  date: _vm.rightImageDate,
                  show: _vm.activeDrawer === "photo-drawer-right",
                  "close-on-select-photo": "",
                  esPublico: _vm.isPublic
                },
                on: {
                  close: function($event) {
                    _vm.activeDrawer = ""
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "titledrawer",
                      fn: function() {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("lang.imagen_derecha.msg")) +
                              " "
                          ),
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "40px",
                                height: "30px",
                                viewBox: "0 0 600 500"
                              }
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "exports",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd"
                                  }
                                },
                                [
                                  _c("g", { attrs: { id: "icn-right" } }, [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M0.0001,29.9998 L0.0001,469.9998 C0.0001,486.5688 13.4321,499.9998 30.0001,499.9998 L285.4761,499.9998 L285.4761,-0.0002 L30.0001,-0.0002 C13.4321,-0.0002 0.0001,13.4318 0.0001,29.9998",
                                        id: "Fill-1",
                                        fill: "#C1C1C1"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M570.0001,-0.0002 L314.5241,-0.0002 L314.5241,499.9998 L570.0001,499.9998 C586.5691,499.9998 600.0001,486.5688 600.0001,469.9998 L600.0001,29.9998 C600.0001,13.4318 586.5691,-0.0002 570.0001,-0.0002",
                                        id: "Fill-4",
                                        fill: "#212A52"
                                      }
                                    })
                                  ])
                                ]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  318418458
                ),
                model: {
                  value: _vm.rightImage,
                  callback: function($$v) {
                    _vm.rightImage = $$v
                  },
                  expression: "rightImage"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }