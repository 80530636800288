var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "card-w-shadow",
                  attrs: { "no-body": "", "header-tag": "header" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("small", [_vm._v(_vm._s(_vm.leftYear))]),
                          _c("h2", [_vm._v(_vm._s(_vm.leftDate))]),
                          _c("hr", { staticClass: "mb-0" })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "p-2" },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-img", {
                            attrs: { fluid: "", src: _vm.leftImage.url }
                          }),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "mt-4 text-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-center"
                                  },
                                  [
                                    _c("DownloadButton", {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDownloadPhotos(
                                            _vm.leftImage
                                          )
                                        }
                                      }
                                    }),
                                    _c("SendButton", {
                                      staticClass: "ml-3",
                                      on: {
                                        click: function($event) {
                                          _vm.openSendPhotoModalLeft = true
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-card",
                {
                  staticClass: "card-w-shadow",
                  attrs: { "no-body": "", "header-tag": "header" },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("small", [_vm._v(_vm._s(_vm.rightYear))]),
                          _c("h2", [_vm._v(_vm._s(_vm.rightDate))]),
                          _c("hr", { staticClass: "mb-0" })
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-card-body",
                    { staticClass: "p-2" },
                    [
                      _c(
                        "b-card-text",
                        [
                          _c("b-img", {
                            attrs: { fluid: "", src: _vm.rigthImage.url }
                          }),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { staticClass: "mt-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-center"
                                  },
                                  [
                                    _c("DownloadButton", {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDownloadPhotos(
                                            _vm.rigthImage
                                          )
                                        }
                                      }
                                    }),
                                    _c("SendButton", {
                                      staticClass: "ml-3",
                                      on: {
                                        click: function($event) {
                                          _vm.openSendPhotoModalRight = true
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("SendPhotoModal", {
        attrs: { photo: _vm.leftImage, open: _vm.openSendPhotoModalLeft },
        on: {
          close: function($event) {
            _vm.openSendPhotoModalLeft = false
          }
        }
      }),
      _c("SendPhotoModal", {
        attrs: { photo: _vm.rigthImage, open: _vm.openSendPhotoModalRight },
        on: {
          close: function($event) {
            _vm.openSendPhotoModalRight = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }